import axios from "axios";

export default {
    namespaced: true,
    state: {
    
    },
    actions: {
        async getOrders(ctx, { page = 0, size = 15 }) {
            return axios.get(
                `/api/v1/marketplace/order/?page=${page}&size=${size}`,
            ).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async getOrder(ctx, { orderId }) {
            return axios.get(
                `/api/v1/marketplace/order/${orderId}`,
            ).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

    },
    getters: {

    },
    mutations: {

    }
};
