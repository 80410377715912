import axios from "axios";

export default {
    namespaced: true,
    state: {
        selfPermissions: [],
        selfGroups: [],
    },
    actions: {
        async getGroupsForSelf(ctx) {
            return axios.get(`/api/v1/user/groups/`).then(result => {
                if (result.data) {
                    ctx.commit('setSelfPermissions', result.data);
                    if(result.data.length !== 0){
                        ctx.commit('setSelfGroups', result.data.map(element => element.name));
                    }else{
                        ctx.commit('setSelfGroups', []);
                    }
                }
                return result;
            }).catch((err) => {
                console.log(err?.response?.data)
                ctx.commit('setSelfPermissions', []);
                return err;
            });
        },
        async getGroups(ctx) {
            return axios.get(`/api/v1/groups/`).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
        },
        async getUsersForGroup(ctx, { group }) {
            return axios.get(`/api/v1/groups/${group}/members`).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
        },
        async getGroupsForUser(ctx, { userId }) {
            return axios.get(`/api/v1/user/${userId}/groups`).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
        },
        async inviteUserForGroup(ctx, { group, request }) {
            return axios.post(`/api/v1/groups/${group}/invite`, request).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
        },
        async acceptInvite(ctx, { inviteId }) {
            return axios.post(`/api/v1/groups/invite/${inviteId}`).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
        },
        async modifyGroupSettings(ctx, { group, request }) {
            return axios.post(`/api/v1/groups/${group}/settings`, request).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
        },
        async removeUserFromGroup(ctx, { group, user }) {
            return axios.delete(`/api/v1/groups/${group}/${user}`).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
        },

        //shop, brokerage, city and listing permissions 
        async getPermissionForSelf(ctx, { type, id }) {
            if(type === 'shop'){
                return await axios.get(`/api/v1/marketplace/${type}/${id}/permissions/self`).catch(err=>{
                    console.log(err?.response?.data)
                    return err
                });
            } else {
                return await axios.get(`/api/v1/${type}/${id}/permissions/self`).catch(err=>{
                    console.log(err?.response?.data)
                    return err
                });
            }
        },
        async getPermissionForUser(ctx, { type, id }) {
            if(type === 'shop'){
                return await axios.get(`/api/v1/marketplace/${type}/${id}/permissions`).catch(err=>{
                    console.log(err?.response?.data)
                    return err
                });
            } else {
                return await axios.get(`/api/v1/${type}/${id}/permissions`).catch(err=>{
                    console.log(err?.response?.data)
                    return err
                });
            }
        },
        async setPermissionForUser(ctx, { type, id, request }) {
            if(type === 'shop'){
                return axios.post(`/api/v1/marketplace/${type}/${id}/permissions`, request).catch(err=>{
                    console.log(err?.response?.data)
                    return err
                });
            } else {
                return axios.post(`/api/v1/${type}/${id}/permissions`, request).catch(err=>{
                    console.log(err?.response?.data)
                    return err
                });
            }
        },
        async deletePermissionForUser(ctx, { type, id, userId }) {
            if(type === 'shop'){
                return axios.delete(`/api/v1/marketplace/${type}/${id}/permissions/${userId}`).catch(err=>{
                    console.log(err?.response?.data)
                    return err
                });
            } else {
                return axios.delete(`/api/v1/${type}/${id}/permissions/${userId}`).catch(err=>{
                    console.log(err?.response?.data)
                    return err
                });
            }
        },
    },
    getters: {
        getSelfPermissions(state) {
            return state.selfPermissions;
        },
        getSelfGroups(state) {
            return state.selfGroups;
        },
    },
    mutations: {
        setSelfPermissions(state, payload) {
            state.selfPermissions = payload;
        },
        setSelfGroups(state, payload) {
            state.selfGroups = payload;
        },
    }
};
