import axios from "axios";

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    getters: {

    },
    actions: {
        async searchRealtorByOpt(ctx, { facet, start = 0, size = 15 }) {
            return axios.post(`/api/v1/user/facet/search?start=${start}&size=${size}`, facet).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async searchRealtor(ctx, { facet, start = 0, size = 15 }){
            return axios.post(`/api/v1/user/facet/search?start=${start}&size=${size}`, facet).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async getByFacetId(ctx, { id }) {
            return axios.post(`/api/v1/user/facet/${id}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async post(ctx, { facet }) {
            return axios.post(`/api/v1/user/facet/`, facet).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async put(ctx, { id, facet }) {
            return axios.put(`/api/v1/user/facet/${id}`, facet).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async delete(ctx, { id }) {
            return axios.delete(`/api/v1/user/facet/${id}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
    },
};