import axios from "axios";

export default {
    namespaced: true,
    state: {

    },
    actions: {
        async getSelfInvoices(ctx, { shopId, page = 0, size = 15 }) {
            return axios.get(
                `/api/v1/marketplace/shop/${shopId}/invoices/?page=${page}&size=${size}`,
            ).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async getInvoice(ctx, { invoiceId }) {
            return axios.get(
                `/api/v1/marketplace/invoice/${invoiceId}`,
            ).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async requestRefund(ctx, { request }) {
            return axios.post(
                `/api/v1/marketplace/invoice/${request.invoiceId}/refund`,
                request
            ).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
    },
    getters: {

    },
    mutations: {

    }
};
