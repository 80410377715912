import axios from "axios";

export default {
    namespaced: true,
    state: {
        shops: [],
    },
    actions: {
        async queryShop(ctx, { shopId }) {
            return axios.get(`/api/v1/marketplace/shop/${shopId}`
            ).then(result => {
                if (result.data) {
                    return result.data;
                }
                return null;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            })
        },
        async getShops(ctx) {
            return axios.get('/api/v1/marketplace/shop/', {
                params: {
                    page: 0,
                    size: 100,
                }
            }).then(async result => {
                let activeShops = result.data.content.filter(shop => shop.active === true);
                if (activeShops && activeShops.length > 0) {
                    ctx.commit('setShops', activeShops);
                } else {
                    ctx.commit('setShops', []);
                }
                return activeShops;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async getShopSetting(ctx, { shopId }) {
            return axios.get(`/api/v1/marketplace/shop/${shopId}/settings`
            ).then(result => {
                if (result.data) {
                    return result.data;
                }
                return null;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            })
        },
        async createShop(ctx, { shop }) {
            return axios.post(`/api/v1/marketplace/shop/`, shop
            ).then(result => {
                if (result.data) {
                    ctx.commit('setShops', [...ctx.getters.getShops, result.data]);
                } else {
                    ctx.commit('setShops', []);
                }
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async updateShop(ctx, { id, shop }) {
            return axios.put(`/api/v1/marketplace/shop/${id}`, shop
            ).then(result => {
                if (result.data) {
                    ctx.dispatch('getShops');
                } else {
                    ctx.commit('setShops', []);
                }
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async deleteShop(ctx, { id }) {
            return axios.delete(`/api/v1/marketplace/shop/${id}`
            ).then(result => {
                ctx.dispatch('getShops');
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async attachPaymentMethod(ctx, { shopId, stripeAccountId }) {
            return axios.post(`/api/v1/marketplace/shop/${shopId}/manage/payment/stripe`, { account_id: stripeAccountId }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        //shipping rate
        async getShippingRateList(ctx, { shopId }) {
            return axios.get(
                `/api/v1/marketplace/shop/${shopId}/shipping?active=${true}`,
            ).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async createShippingRate(ctx, { shopId, rate }) {
            return axios.post(
                `/api/v1/marketplace/shop/${shopId}/shipping`,
                rate
            ).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async updateShippingRate(ctx, { shopId, rateId, request }) {
            return axios.put(
                `/api/v1/marketplace/shop/${shopId}/shipping/${rateId}`,
                request
            ).then(res => {
                return res.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

    },
    getters: {
        getShops(state) {
            return state.shops;
        },
    },
    mutations: {
        setShops(state, payload) {
            state.shops = payload;
        },
    }
};
