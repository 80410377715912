import axios from "axios";

export default {
	namespaced: true,
	state: {},
	mutations: {},
	getters: {},
	actions: {
		async createPartner(ctx, {userId}) {
			return axios.post(`/api/v1/partner/${userId}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		
		async deletePartner(ctx, {userId}) {
			return axios.delete(`/api/v1/partner/${userId}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		
		async getPartnerSettings(ctx, {userId}) {
			return axios.get(`/api/v1/partner/${userId}/settings`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		
		async setPartnerSettings(ctx, {userId, commission, note}) {
			return axios.post(`/api/v1/partner/${userId}/settings`, {
				commission,
				note,
			}).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		
		/**
		 * Coupons
		 */
		
		
		async createCoupon(ctx, {
			userId,
			
			// integer
			amount_off,
			
			// string
			coupon_code,
			
			// enum  Allowed: FOREVER┃ONCE┃REPEATING
			duration,
			
			// integer
			duration_in_months,
			
			// string
			nickname,
			// integer
			percent_off,
			
			// date-time
			redemption_date,
			
		}) {
			return axios.post(`/api/v1/partner/coupon/${userId}`, {
				amount_off,
				coupon_code,
				duration,
				duration_in_months,
				nickname,
				percent_off,
				redemption_date,
			}).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		
		async deleteCoupon(ctx, {couponCode}) {
			return axios.delete(`/api/v1/partner/coupon/${couponCode}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		
		/**
		 * This will retrieve all the coupons attached to the user who is making the request. This is NOT all the coupons in the system
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		async listCoupons() {
			return axios.get(`/api/v1/partner/coupons`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		
		async partnerCoupons(ctx, {userId}) {
			return axios.get(`/api/v1/partner/${userId}/coupons`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		
		async partnerSettingsSearch(ctx, {
			userIds,
			note = undefined,
			start = 0,
			size = 9999,
		}) {
			return axios.post(`/api/v1/partner/settings/search?start=${start}&size=${size}`, {
				ids: userIds,
				note: note,
			}).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
	},
};