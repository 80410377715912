import axios from "axios";

export default {
	namespaced: true,
	state: {},
	mutations: {},
	getters: {},
	actions: {
		async search(ctx, {size}) {
			return axios.post(`/api/v1/billboard/search`,
				{},
				{
					params: {
						size: size,
					}
				}).catch(err=>{
					console.log(err?.response?.data)
					return err
				});
		},
		
		async getAll(ctx, {size = 3000}) {
			return this.dispatch('api/billboard/search', {size: size}).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
		},
		
		async getByIds(ctx, {ids = []}) {
			return this.dispatch('api/billboard/getAll', {})
			.then((response) => {
				return response.data.results.filter(item => ids.includes(item.id))
			}).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
			
			// TODO: do nor remove, will use after get access to the endpoint for not SignIn user
			// let requests = ids.map((id)=>{
			//     return axios.get(`/api/v1/billboard/${id}`)
			// });
			//
			// return axios.all(requests)
			//     .then((response) => {
			//         return response.map(v => v.data);
			//     })
		},
		async findOneRandomSponsor(ctx, {id }) {
			return axios.get(`/api/v1/billboard/${id}/sponsor`).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
		},
		async findClosestSponsor(ctx,{start, size, dto} ) {
			return axios.post(`/api/v1/billboard/closest?start=${start}&size=${size}`, dto).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
		},
		async getSponsorsByBillboardId(ctx, {id}) {
			return axios.get(`/api/v1/billboard/${id}/sponsors`).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
		},
		async addSponsor(ctx, {id, dto}) {
			return axios.post(`/api/v1/billboard/${id}/sponsor`, dto).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
		},
		async deleteSponsor(ctx, {id}) {
			return axios.delete(`/api/v1/billboard/sponsor/${id}`).catch(err=>{
                console.log(err?.response?.data)
                return err
            });
		},
	},
};