import axios from "axios";

export default {
    namespaced: true,
    state: {
        taxCode: [],
    },
    actions: {
        async getTaxCode(ctx) {
            return axios.get(`/api/v1/stripe/tax/codes`).then(result => {
                if (result.data) {
                    ctx.commit('setTaxCode', result.data);
                }
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async getAccountTaxSettings(ctx, { stripeAccountId }) {
            return axios.get(`/api/v1/stripe/tax/${stripeAccountId}/settings`).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async updateTaxSettings(ctx, { stripeAccountId, taxSettings }) {
            return axios.put(`/api/v1/stripe/tax/${stripeAccountId}/settings`, { ...taxSettings }).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
        async getTaxRegistration(ctx, { stripeAccountId }) {
            return axios.get(`/api/v1/stripe/tax/${stripeAccountId}/registration`).then(result => {
                return result.data;
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
    },
    getters: {
        getTaxCode(state) {
            return state.taxCode;
        },
    },
    mutations: {
        setTaxCode(state, payload) {
            state.taxCode = payload;
        },
    }
};
