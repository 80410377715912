<template>
	<v-app style="overflow-x:hidden; font-family: 'Roboto', sans-serif;">
		<PersistentNavbar @activateNav="$refs.navbar.trigger()" />

		<!--
		<ProfileNavbar ref="navbar" v-model="navbar" :loading="loading" v-if="loggedIn || $vuetify.breakpoint.mobile"/>
		-->
		<NavigationDrawer ref="navbar" />
		<v-main id="main">
			<v-container fluid class="pa-0">
				<v-row no-gutters>
					<v-col cols="12" class="pa-0">
						<router-view :loading="loading" />
					</v-col>
				</v-row>
			</v-container>
		</v-main>
		<PersistentFooter />
		<div class="scroll-to-top">
			<v-col class="pa-0 d-flex flex-column" cols="auto">
				<v-btn class="mb-2" v-if="isShow" fab color="#ffeb3b" @click="clickScrollToTop">
					<v-icon>mdi-arrow-collapse-up</v-icon>
				</v-btn>
				<v-btn v-if="isShow" fab color="#ffeb3b" @click="clickScrollToBottom">
					<v-icon>mdi-arrow-collapse-down</v-icon>
				</v-btn>
			</v-col>
		</div>
	</v-app>
</template>

<script>
import PersistentNavbar from "@/components/PersistentNavbar";
import PersistentFooter from "@/components/PersistentFooter";
import ProfileNavbar from "@/components/user/ProfileNavbar";
import NavigationDrawer from "@/refactor/components/NavigationDrawer.vue";


export default {
	name: "App",

	components: {
		NavigationDrawer,
		ProfileNavbar,
		PersistentFooter,
		PersistentNavbar,
	},
	data: () => ({
		navbar: false,
		focus: true,
		timer: null,
		loading: false,
		isShow: false,
	}),
	methods: {
		pollUser() {
			if (this.focus)
				this.$store.dispatch('user/getUser');
		},
		initForSignInUser() {
			this.initBookmarks();
			this.initNotificationsCount();
		},

		initBookmarks() {
			this.$store.dispatch('api/bookmarks/initBookmarks', {});
		},

		initNotificationsCount() {
			this.$store.dispatch('api/inbox/requestUnreadCount');
		},
		clickScrollToTop() {
			this.$helpers.scrollTo(0);
		},
		clickScrollToBottom() {
			let footerHeight = this.cellphone ? 1400 : 800;
			const scrollPosition = document.documentElement.scrollHeight - footerHeight;
			this.$helpers.scrollTo(scrollPosition);
		},
		handleScroll() {
			this.isShow = window.scrollY > 400;
		},
	},
	computed: {
		mobile() {
			return this.$vuetify.breakpoint.mobile
		},
		cellphone() {
			return this.$vuetify.breakpoint.xs
		},
		loggedIn() {
			return this.$store.getters['user/getLoggedIn'];
		},
		user() {
			return this.$store.getters['user/getUser'];
		},
	},
	created() {
		let self = this;
		this.loading = true;
		const fetchUserAndShops = async () => {
			await this.$store.dispatch('user/getUser');
			await this.$store.dispatch('api/acl/getGroupsForSelf');
			if (this.loggedIn) {
				this.$store.dispatch('api/shop/getShops');
				this.$store.dispatch('api/cart/getCart');
				this.$store.dispatch('api/rule/getAllNewMatched', { user: this.user });
			}
			this.loading = false;
		};

		// check the visiblility of the page
		window.addEventListener('focus', function () {
			self.focus = true;
		});

		window.addEventListener('blur', function () {
			self.focus = false;
		});

		window.setTimeout(() => {
			window.setInterval(this.pollUser, 300000);
		}, 20000)

		fetchUserAndShops();
		this.$helpers.scrollToTop();
	},
	updated() {

	},
	watch: {
		'$vuetify.breakpoints.mobile'() {
			this.navbar = false;
		},

		loggedIn(v) {
			if (v) {
				this.initForSignInUser();
			}
		},
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	}
};
</script>

<style lang="css">
@import "//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons";

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #0b4073;
}

::-webkit-scrollbar-thumb {
	background: #5d8cb9;
}

::-webkit-scrollbar-thumb:hover {
	background: #b6b6b6;
}

.v-application {
	padding: 0 !important;
}

.v-application .primary--text {
	color: white !important;
}

.v-divider {
	margin: 25px;
}

.v-application a {
	text-decoration: none;

	color: inherit;
}

.scroll-to-top {
	position: fixed;
	bottom: 70px;
	right: 20px;
	z-index: 3;
}
</style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");
</style>