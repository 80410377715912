/* eslint-disable */

import axios from "axios";
import Vue from "vue";

let response = {
	results: [
		{
			id: 105,
		},
		{
			id: 110,
		},
	]
}

export default {
	namespaced: true,
	state: {
		bookmarks: [],
		bookmarksResponse: null,
		totalElements: null,
	},
	
	mutations: {
		setBookmarksResponse(state, bookmarksResponse) {
			Vue.set(state, 'bookmarksResponse', bookmarksResponse);
		},
		setBookmarks(state, bookmarks) {
			Vue.set(state, 'bookmarks', bookmarks);
		},
		
		setTotalElements(state, totalElements) {
			Vue.set(state, 'totalElements', totalElements);
		}
	},
	
	getters: {
		getBookmarksResponse(state) {
			return state.bookmarksResponse;
		},
		getBookmarks(state) {
			return state.bookmarks;
		},
		
		getTotalElements(state) {
			return state.totalElements;
		}
	},
	
	actions: {
		
		async initBookmarks(ctx, {start = 0, size = 999999999}) {
			ctx.dispatch('requestBookmarks', { start, size})
			.then((response) => {
				ctx.commit('setBookmarksResponse', response.data);
				ctx.commit('setBookmarks', response.data.results);
				ctx.commit('setTotalElements', response.data.totalElements);
			}).catch((err)=>{
				ctx.commit('setBookmarksResponse', null);
				ctx.commit('setBookmarks', []);
				ctx.commit('setTotalElements', null);
				console.log(err?.response?.data)
				return err
			})
		},
		
		async requestBookmarks(ctx, {start = 0, size = 999999999}) {
			return axios.get(`/api/v1/listing/bookmarks?start=${start}&size=${size}`).catch(err=>{
				console.log(err?.response?.data)
				return err
			});
		},
		
		/**
		 * @param {Number} listingId
		 * @returns {Promise<any>}
		 */
		async post({}, {listingId}) {
			return axios.post(`/api/v1/listing/bookmark/${listingId}`).catch(err=>{
				console.log(err?.response?.data)
				return err
			});
		},
		
		/**
		 * @param {Number} listingId
		 * @returns {Promise<any>}
		 */
		async delete({}, {listingId}) {
			return axios.delete(`/api/v1/listing/bookmark/${listingId}`).catch(err=>{
				console.log(err?.response?.data)
				return err
			});
		},
	},
};