import axios from "axios";

export default {
    namespaced: true,
    state: {

    },
    actions: {
        async requestProducts(ctx, { query, page = 0, size = 15 }) {
            let request;

            if (query)
                request = { ...query };
            else
                request = {};

            if (!!!request['distance']) {
                request['distance'] = 160000;
            }

            // Sending null keys is bad.
            Object.keys(request).forEach(key => {
                if (request[key] == null)
                    delete request[key];
            });

            return axios.post(`/api/v1/marketplace/search?page=${page}&size=${size}`, request).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async getSelfProducts(ctx, { shopId, query, page = 0, size = 15 }) {
            let request;
            if (query)
                request = { ...query };
            else
                request = {};

            // Sending null keys is bad.
            Object.keys(request).forEach(key => {
                if (request[key] == null)
                    delete request[key];
            });

            return axios.get(`/api/v1/marketplace/shop/${shopId}/products?page=${page}&size=${size}`, {
                params: {
                    ...request,
                }
            }).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async queryProduct(ctx, { shopId, productId }) {
            return axios.get(`/api/v1/marketplace/shop/${shopId}/product/${productId}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async createProduct(ctx, { shopId, product }) {
            return axios.post(`/api/v1/marketplace/shop/${shopId}/product`, product).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async updateProduct(ctx, { shopId, productId, product }) {
            return axios.post(`/api/v1/marketplace/shop/${shopId}/product/${productId}`, product).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async productDeactivate(ctx, { shopId, productId }) {
            return axios.post(`/api/v1/marketplace/shop/${shopId}/product/${productId}/deactivate`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },

        async productActivate(ctx, { shopId, productId }) {
            return axios.post(`/api/v1/marketplace/shop/${shopId}/product/${productId}/activate`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
        },
    },
    getters: {

    },
    mutations: {

    }
};
