import axios from "axios";

export default {
	namespaced: true,
	state: {
		hasNewMatched: 0,
	},
    getters: {
        getHasNewMatched(state) {
            return state.hasNewMatched;
        },
    },
    mutations: {
        setHasNewMatched(state, payload) {
            state.hasNewMatched = payload;
        },
    },
	actions: {
        async createRule(ctx, { type, rule }) {
			return axios.post(`/api/v1/rule/${type}/`, rule).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
        async updateRule(ctx, { type, rule, id }) {
			return axios.put(`/api/v1/rule/${type}/${id}`, rule).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		async deleteRule(ctx, { type, id }) {
			return axios.delete(`/api/v1/rule/${type}/${id}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		async requestRuleById(ctx, { type, id }) {
			return axios.get(`/api/v1/rule/${type}/${id}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		async requestListingsForRule(ctx, { type, id, start, size, newOnly }) {
			return axios.get(`/api/v1/rule/${type}/${id}/listings?start=${start}&size=${size}&showOnlyNew=${newOnly}`).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		async requestRules(ctx,  { type,  start, size, request }){
			return axios.post(`/api/v1/rule/${type}/search?start=${start}&size=${size}`, request).catch((err) => {
                console.log(err?.response?.data)
                return err;
            });
		},
		async getAllNewMatched(ctx, { user }){
			let ruleList = {
				property: [],
				item: [],
				event: [],
				classified: [],
			};
			let promises = Object.keys(ruleList).map(type => {
				return ctx.dispatch('requestRules', {
					type: type,
					start: 0,
					size: 10,
					request: {
						name: null,
                        owner: user.id,
					},
				}).then((res) => {
					if (!res.data.empty) {
						ruleList[type] = res.data.content.map(rule => {
							return rule.id
						});
					}
				}).catch((err) => {
					console.log(err?.response?.data)
					return err;
				});
			});

			Promise.all(promises).finally(() => {
				let newMatchedListings = {};
				let listingPromises = [];
				for (let type in ruleList) {
					for (let id of ruleList[type]) {
						let promise = ctx.dispatch('requestListingsForRule', {
							type: type,
							id: id,
							start: 0,
							size: 1,
							newOnly: false,
						}).then((res) => {
							newMatchedListings[id] = res.data.totalElements;
						}).catch((err) => {
							console.error(`Error fetching listings for rule ${id}:`, err);
						});
						listingPromises.push(promise);
					}
				}

				Promise.all(listingPromises).finally(() => {
					let result = 0;
					for(let key in newMatchedListings){
						if(newMatchedListings[key] > 0){
							result += 1;
						}
					}
					ctx.commit('setHasNewMatched', result);
				});
			});
		}
		
	},
};